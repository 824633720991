<template>
    <footer class="mt-16 mb-5 px-6 flex flex-col md:flex-row md:items-end md:justify-between">
        <div>
            <router-link to="/">
                <div class="w-8 my-6">
                    <img src="../assets/images/corazon_aliada.png" alt="">
                </div>
            </router-link>

            <p class="footer-text w-full md:w-2/3">¿Necesitas ayuda? Envíanos un mensaje directo
                por
                chat o escríbenos a:
                <span class="text-blue-100"><a href="mailto:info@aliada.mx" target="_blank"
                        rel="noopener noreferrer">info@alida.mx</a></span></p>
            <p class="footer-text">Todos los derechos reservados</p>
        </div>
        <div class="flex flex-col">
            <a href="https://aliada.mx/terminos" class="footer-text text-blue-100">Términos y condiciones</a>
            <a href="https://aliada.mx/privacidad" class="footer-text text-blue-100">Políticas de privacidad</a>
            <a href="https://aliada.mx/preguntas-frecuentes" class="footer-text text-blue-100">Preguntas frecuentes</a>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>