<template>
    <span class="dot"></span>
</template>

<script>
export default {
    name:'Dot'
}
</script>

<style>
    .dot {
        height: 25px;
        width: 25px;
        background-color: #2055ec;
        border-radius: 50%;
        display: inline-block;
    }
</style>