<template>
  <main>
    <section class="pt-24 flex-centered bg-blue-200 lg:flex-centered-row">
      <div class="px-5 w-full mt-4 lg:pl-10 lg:w-35">
        <h1 class="font-raleway font-bold text-5xl leading-snug tracking-normal">Tu cómplice para un hogar limpio</h1>
        <p class=" text-xl my-10 lg:pr-24 lg:text-xl">Conectamos a profesionales de la limpieza de forma rápida y segura.</p>
        <a href="https://onboarding.aliada.mx/" target="blank" class="btn-blue py-4 px-3">BUSCAR ALIADAS DISPONIBLES</a>
      </div>
      <div class="my-16 w-4/5 lg:w-3/5 flex justify-items-center"><img alt="" src="../assets/images/home_hero.png">
      </div>
    </section>
    <section class="sm:flex-centered bg-white-200 pt-12">
        <SectionTitle text="Beneficios de aliada" class="px-5 py-6 text-center"></SectionTitle>
        <SectionSubtitle text="Estar en casa nunca había sido tan reconfortante." class="text-center px-8 mb-12"></SectionSubtitle>
        <div class="flex-centered">
        <div class="flex flex-col items-start h-50 px-8 py-4">
            <div class="w-1/3 mb-6 h-24">
            <img src="../assets/images/pencil.png" alt="" srcset="" class="object-contain w-full h-full">
            </div>
            <h4 class="mb-6 font-lato font-bold text-xl md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-1">Seguridad</h4>
            <p class=" leading-relaxed">Todas las aliadas y aliados pasan por distintos filtros de seguridad (pruebas psicológicas, psicométricas y de confianza). </p>
        </div>
        <div class="flex flex-col items-start h-50 px-8 py-4">
            <div class="w-1/3 mb-6 h-24">
            <img src="../assets/images/choose.png" alt="" srcset="" class="object-contain w-full h-full">
            </div>
            <h4 class="mb-6 font-lato font-bold text-xl md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-1">Tú eliges</h4>
            <p class=" leading-relaxed">Podrás elegir a la persona que limpiará tu casa. Estamos seguros de que encontrarás a tu Aliada ideal.</p>
        </div>
        <div class="flex flex-col items-start h-50 px-8 py-4">
            <div class="w-1/3 mb-6 h-24">
            <img src="../assets/images/hands.png" alt="" srcset="" class="object-contain w-full h-full">
            </div>
            <h4 class="mb-6 font-lato font-bold text-xl md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-1">Sé parte del impacto social</h4>
            <p class=" leading-relaxed">Todas las Aliadas están aseguradas y cuentan con ingresos justos. Además ellas tienen la flexibilidad de elegir en dónde y cuándo trabajar.</p>
        </div>
        </div>
    </section>
    <section class="flex-centered items-start bg-blue-200 pb-12">
        <div class="px-5 sm:w-full md:w-2/5 pt-10">
            <SectionTitle text="Acerca de los servicios" class="text-center md:text-left"></SectionTitle>
            <SectionSubtitle text="Haz lo que te gusta, aliada te ayuda con las tareas del hogar."></SectionSubtitle>
        </div>
        <div
        class="w-full mt-12 flex-centered flex-col-md md:w-5/12">
            <Question question="¿Qué puede hacer mi aliada?" filename="escoba.png"
                text="Tu Aliada te ayudará con la limpieza general de tu casa (barrer, trapear, sacudir, lavar trastes). Además, podrás encontrar a personas que ofrecen servicio de lavado de ropa, planchar e incluso cocinar.">
            </Question>
            <Question question="¿Incluye materiales de limpieza?" filename="productos.png" text="No, tu servicio no incluye materiales ni productos de limpieza. Tu Aliada te podrá aconsejar acerca de qué productos comprar para dejar tu casa reluciente."></Question>
            <Question question="¿En qué ciudades tienen cobertura?" filename="location.png" text="Puedes reservar tu servicio de limpieza a partir de 3 horas, con la frecuencia que lo necesites. Desde un sólo servicio, una vez a la semana e incluso servicios urgentes"></Question>
            <Question question="¿Con qué frecuencia puedo reservar?" filename="calendario.png" text="Puedes reservar tu servicio de limpieza a partir de 3 horas, con la frecuencia que lo necesites. Desde un sólo servicio, una vez a la semana e incluso servicios urgentes"></Question>
        </div>

    </section>
    <section class="flex flex-col justify-end items-end app h-40 md:h-85 bg-cover bg-right">
        <div class="px-3 md:px-5 w-56 md:w-2/5 pb-0 md:pb-5">
            <h2 class="text-2xl font-raleway font-medium mb-5 md:text-3xl">Para una <span class="font-bold">mejor experiencia</span>, descarga la app</h2>
        </div>
        <div class="px-3 pb-10 md:pb-40 md:px-5 w-56 md:w-2/5 flex justify-left">
            <a href="https://play.google.com/store/apps/details?id=mx.aliada.user_app" target="_blank" rel="noopener noreferrer" class="w-5/12 md:w-4/12 mr-6"><img src="../assets/images/play_store.png" alt="" srcset=""></a>
            <a href="https://apps.apple.com/mx/app/aliada-servicios-de-limpieza/id1525467580" target="_blank" rel="noopener noreferrer" class="w-5/12 md:w-4/12"><img src="../assets/images/app_store.png" alt="" srcset=""></a>
        </div>
    </section>
  </main>

</template>

<script>
import SectionTitle from '../components/SectionTitle'
import SectionSubtitle from '../components/SectionSubtitle'
import Question from '../components/Question'

export default {
  name: 'Home',
  components: {
      'SectionTitle': SectionTitle,
      'SectionSubtitle': SectionSubtitle,
      'Question': Question
      }
}
</script>

<style>
    .app{
        background-image: url('../assets/images/app.png');
    }
    @media (min-width: 768px){
        .app{
            background-position-x: 50%;
            background-position-y: 40%;
        }
    }
</style>
