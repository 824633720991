<template>
    <div class="px-4 py-4">
        <div class="flex">
            <div class=" w-8">
                <Dot></Dot>
            </div>
            <a class="question-bttn"> 
                <h2 class="px-6 font-lato text-lg font-bold">{{question}}</h2>
            </a>
        </div>
        <div class="pt-5 answer flex">
            <div class="vertical-line"></div>
            <img class="h-20 px-8" v-bind:src="require(`../assets/images/${filename}`)">
            <p class="text-sm font-lato text-gray-300">{{text}}</p>
        </div>
    </div>
</template>

<script>
import Dot from './Dot'

export default {
    name: 'Question',
    components:{
        'Dot': Dot
    },
    props:['question', 'filename', 'text'],
}
</script>

<style>
    .vertical-line {
        border-left: thick solid #adccff;
        margin-left: 2.7%
    }

    @media (min-width: 768px){
        .vertical-line{
            margin-left:2%
        }
    }

</style>
