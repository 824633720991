import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Benefits from "../views/Benefits.vue";
import ThankYou from "../views/ThankYou.vue";

const routes = [
	{ path: "/", component: Home },
	{ path: "/beneficios", component: Benefits },
	{ path: "/gracias", component: ThankYou },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

export default router;
