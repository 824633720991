
<template>
    <p class="text-2xl font-raleway font-medium mb-12 md:text-3xl">{{text}}</p>
</template>

<script>
export default {
  name: 'SectionSubtitle',
  props: ['text']
}
</script>