<template>
    <main>
        <section class="pt-24 flex-centered md:flex-centered-row">
            <div class="px-5 w-full md:pl-10 md:w-2/5">
                <h1 class="font-raleway font-bold text-5xl leading-none tracking-wider">Es tiempo <span
                        class="text-blue-100">de cuidar</span>
                    a
                    quienes
                    <span class="text-blue-100">nos han
                        cuidado</span></h1>
                <p class="mobile-p my-10">Protege a las personas que trabajan en tu casa con nuestro plan
                    de
                    beneficios</p>
            </div>

            <div class="mt-6 w-4/5 md:w-2/5"><img alt="Aliada con uniforme" src="../assets/images/aliada_uniform.png">
            </div>
        </section>
        <section class="flex-centered md:flex-centered-row bg-white-300">
            <div class="my-6 w-11/12 md:w-2/5"><img alt="Cliente cuestionándose sobre cómo ayudar a su Aliada"
                    src="../assets/images/client.png"></div>
            <div class="px-5 w-full md:w-2/5">
                <h2 class="section-title">Tú puedes hacer una
                    gran
                    diferencia</h2>
                <p class="mobile-p mb-10">Los planes que aliada te ofrece, protegen y cuidan de tus empleadas(os)
                    domésticos.
                    Están pensandos para
                    cubrir los problemas frecuentes con los que todos los días se enfrentan estas personas: falta de
                    seguridad médica que cubra accidentes o enfermedades, no cuentan con prestaciones de ley (aguinaldo,
                    vacaciones pagadas),etc. </p>
            </div>

        </section>
        <section class="sm:flex-centered">
            <h2 class="section-title px-5 md:text-center">Hecha un vistazo a otras realidades</h2>
            <p class="mobile-p px-5 mb-10 md:text-center">Tres de cada cuatro trabajadoras del hogar (1.6 millones)
                trabaja sin
                derechos laborales
                ni seguridad
                social*.</p>
            <div class="flex-centered">
                <div class="flex-centered grid-statistics">
                    <h3 class="accent-text md:row-start-1 md:row-span-1 md:col-start-1 md:col-span-1">98%</h3>
                    <h4 class="accent-text-gray md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-1">
                        No tiene
                        acceso a
                        instituciones de salud</h4>
                    <img class="md:row-start-1 md:row-span-2" alt="diamond" src="../assets/images/diamond_bullet.png">
                </div>
                <div class="flex-centered grid-statistics">
                    <h3 class="accent-text md:row-start-1 md:row-span-1 md:col-start-1 md:col-span-1">93%</h3>
                    <h4 class="accent-text-gray md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-1">No tiene
                        vacaciones con goce de sueldo</h4>
                    <img class="md:row-start-1 md:row-span-2" alt="diamond" src="../assets/images/diamond_bullet.png">
                </div>
                <div class="flex-centered grid-statistics">
                    <h3 class="accent-text md:row-start-1 md:row-span-1 md:col-start-1 md:col-span-2">74%</h3>
                    <h4 class="accent-text-gray md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-2">No
                        tiene
                        ninguna prestación laboral</h4>
                </div>
            </div>

            <p class="caption px-5 my-10 md:px-10">* Datos INMUJERES <span class="italic">“Cuadernillo Trabajadoras del
                    Hogar
                    Remuneradas en México”.</span> 2019</p>
        </section>
        <section class="flex-centered">
            <div class="px-5 sm:w-full md:w-2/5">
                <h2 class="section-title">Nuestros planes y precios</h2>
                <p class="mobile-p mb-6">En aliada sabemos la importancia de mantener la asistencia y el servicio
                    de tu
                    empleada
                    doméstica. Tener
                    a las personas felices y hacerlas sentir valoradas, ayuda a disminuir la rotación.</p>
                <p class="mobile-p mt-6">Nuestros planes, están pensandos para tu bienestar y el de las personas
                    que
                    trabajan en
                    tu casa. Evita
                    imprevistos y gastos innecesarios.</p>
                <div class="flex-centered flex-col-md">
                    <p class="font-lato italic text-base leading-looses tracking-wider mt-10 mb-3"><span
                            class="font-raleway font-semibold text-6xl leading-none tracking-wider text-blue-100">"</span>
                        Llevo
                        más
                        de
                        2 años con mis clientes. Me gusta mucho como me tratan
                        y me siento agradecida de trabajar con ellos.</p>
                    <div class="grid grid-cols-3 grid-rows-2 w-4/5">
                        <img class="w-4/5 rounded-full row-start-1 row-span-2 self-center justify-self-end"
                            src="../assets/images/aliada_circle_avatar.png" alt="">
                        <p class="mobile-p p-0 m-0 col-start-2 col-span-2 row-start-1 row-span-1 self-end">Gabriela
                            Calvillo
                        </p>
                        <p class="caption col-start-2 col-span-2 row-start-2 row-span-1">Aliada desde 2015</p>
                    </div>
                </div>
            </div>

            <div
                class="bg-texture bg-no-repeat bg-left-bottom md:bg-cover md:bg-center w-full mt-12 flex-centered flex-col-md md:w-5/12">
                <div class="card">
                    <h5 class="title-card">Básico</h5>
                    <div class="flex justify-between mb-6">
                        <p class="p-card">
                            <span></span>Reembolso hasta por $25,000 para atención médica en caso de accidente <br/>
                            <span></span>+ Consultas médicas telefónicas las 24 hrs<br/>
                            <span></span>+ Aguinaldo
                        </p>
                        <p class="accent-text-gray text-base p-0 my-3">$ <span class="span-card">275</span>
                            /mens
                        </p>
                    </div>
                </div>
                <div class="card">
                    <h5 class="title-card">Protección Media</h5>
                    <div class="flex justify-between mb-6">
                        <p class="p-card">
                            <span></span>Incluye Plan Básico<br/>
                            <span></span>+ 4 Consultas con médicos especialistas al año<br/>
                            <span></span>+ Prima Vacacional<br/>
                            <span></span>+ Adelanto de sueldo<br/>
                            <span></span>+ Préstamos para imprevistos
                        </p>
                        <p class="accent-text-gray text-base p-0 my-3">$ <span class="span-card">450</span> /mens</p>
                    </div>
                </div>
                <div class="card">
                    <h5 class="title-card">Protección Social</h5>
                    <div class="flex justify-between mb-6">
                        <p class="p-card">
                            <span></span> Incluye Plan Protección Media<br/>
                            <span></span> + IMSS
                        </p>
                        <p class="accent-text-gray text-base p-0 my-3">$ <span class="span-card">1,800</span> /mens</p>
                    </div>
                </div>
            </div>
        </section>
        <Contact></Contact>
    </main>

</template>

<script>
import Contact from '../components/Contact'

export default {
  name: 'Benefits',
  components:{
      'Contact': Contact
  },
}
</script>

<style>
    .bg-texture {
        background-image: url('../assets/images/background_image.png');
    }

    .select-input{ 
        background-image: url('../assets/images/down-arrow.svg');
    }
</style>