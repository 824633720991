<template>
    <section id="contacto" class="flex-centered">
        <div class="px-5 w-full md:w-2/5">
            <h2 class="section-title">Déjanos tus datos, en breve te contactaremos</h2>
            <p class="mobile-p mb-3">Con gusto atenderemos tus dudas y te ayudaremos a registrar al personal
                que
                labora en tu
                casa.</p>
            <div class="hidden md:block w-3/4"><img src="../assets/images/phone_illustration.png" alt="" srcset="">
            </div>
        </div>
        <div class="w-full max-w-xs md:max-w-lg">
            <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="mb-8">
                    <input class="text-input placeholder-gray-300 focus:outline-none" id="username" input type="text" v-model="userName"
                        placeholder="Nombre & Apellido">
                </div>
                <div class="mb-8">
                    <input class="text-input placeholder-gray-300 focus:outline-none" id="phone-number" type="text" v-model="phoneNumber"
                        placeholder="Teléfono de contacto">
                </div>
                <div class="mb-8">
                    <input class="text-input placeholder-gray-300 focus:outline-none" id="email" type="email" v-model="email"
                        placeholder="Correo">
                </div>
                <div>
                    <label class="title-card">¿Cuál plan te interesa?</label>
                    <select class="select-input placeholder-gray-300 focus:outline-none" v-model="selected">
                        <option value="Básico">Básico ($5 mens)</option>
                        <option value="Protección Media" selected>Protección Media ($100 mens)</option>
                        <option value="Protección Social">Protección Social ($1,500)</option>
                    </select>

                </div>
                <div class="flex items-center justify-between mt-5">
                    <button class="btn-blue w-full m-0" type="button" v-on:click="slackNotify">
                        QUIERO MÁS INFORMACIÓN
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Contact',
    methods: {
        slackNotify: function () {
            const MY_SLACK_WEBHOOK_URL =
                "https://hooks.slack.com/services/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";
            const slack = require("slack-notify")(MY_SLACK_WEBHOOK_URL);

            if(this.userName == "" || this.phoneNumber == "" || this.email == ""){
                alert("Es necesario llenar todos los campos de información, por favor");
            } else{
                slack.send({
                    channel: "#notificaciones-baas",
                    username: "Landing de Beneficios",
                    text: `Nombre: ${this.userName}, \n Teléfono: ${this.phoneNumber}, \n Email: ${this.email}, \n Plan: ${this.selected}`,
                });
                this.$router.push({ path: 'gracias'});
            }
        }
    },
    data: function () {
        return {
        userName: "",
        phoneNumber:"",
        email:"",
        selected:""
        }
    }
}
</script>