<template>
  <main class=" pt-16">
    <div class="flex items-center justify-center h-50 thank-you-hero">
    </div>
    <div class="px-6 pt-6 w-full md:w-2/5 md:px-10">
        <h1 class="font-raleway font-semibold text-5xl">¡Gracias! 🙌</h1>
        <p class="font-lato font-normal text-lg md:text-xl pt-6">Muchas gracias por tu interés y por compartinos tus datos. Pronto nos comunicaremos contigo para brindarte más información.</p>
    </div>
  </main>
</template>

<script>

export default {
  name: 'ThankYou',
}
</script>

<style>
  .thank-you-hero{
    background-image: url('../assets/images/confirmation.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (min-width: 798px){
    .thank-you-hero{
      background-image: url('../assets/images/confirmation.png');
      background-size: contain;
      background-position: center;
      background-repeat: repeat;
    }
  }
</style>