<template>
    <header class="bg-white-200 fixed">
        <div class="nav-bar h-16 mb-3 md:grid-cols-3">
            <div class="w-full row-start-1 row-span-1 col-start-1 col-span-2 flex justify-center md:w-1/3">
                <router-link to="/" class="w-1/4 md:w-1/3">
                    <img src="../assets/images/aliada_logotipo.png" alt="Logo de Aliada">
                </router-link>
            </div>
            <div class="row-start-1 row-span-1 col-start-2 z-10 block md:hidden">
                <button id="menu-icon" class="w-5 md:w-0">
                    <img src="../assets/images/menu_icon.png" alt="Ícono de Menú">
                </button>
            </div>
            <div class="desktop-header">
                <a href="https://aliada.mx/como-funciona" class="menu-links">Acerca de Aliada</a>
                <a href="https://aliada.mx/limpieza-en-oficinas" class="menu-links">Para oficina</a>
                <router-link to="/" class="menu-links">Para Hogar</router-link>
                <router-link to="/beneficios" class="menu-links">Beneficios</router-link>
                <a href="https://aliada.mx/sanitizacion-y-desinfeccion" class="menu-links">Sanitización</a>
                <a href="https://aliada.mx/login" class="font-roboto text-blue-100">INICIAR SESIÓN</a>
            </div>
        </div>
        <nav id="menu" class="flex-col fixed h-wide w-3/5 bottom-0 right-0 bg-white-200 px-6 py-5 hidden">
            <p class="menu-links">¡Hola! Te estábamos esperando.</p>
            <hr class="mt-4 text-gray-100 border-gray-100 border-1">
            <ul>
                <li class="mt-5"><a href="https://aliada.mx/seguridad-y-confianza" class="menu-links">Acerca de
                        Aliada</a></li>
                <li class="mt-5"><a href="https://aliada.mx/limpieza-en-oficinas" class="menu-links">Para Oficina</a>
                </li>
                <li class="mt-5"><router-link to="/" class="menu-links">Para Hogar</router-link></li>
                <li class="mt-5"><router-link to="/beneficios" class="menu-links">Beneficios</router-link></li>
                <li class="mt-5"><a href="https://aliada.mx/sanitizacion-y-desinfeccion"
                        class="menu-links">Sanitización</a></li>
                <li class="mt-5"><a href="https://aliada.mx/login" class="font-roboto text-blue-100">INICIAR SESIÓN</a>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style>
    .nav-bar {
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 90% 10%;
        align-items: center;
    }
</style>
