<template>
    <navBar></navBar>
    <router-view></router-view>
    <Footer></Footer>
</template>

<script>

import Footer from './components/Footer'
import NavBar from './components/NavBar'

export default {
  name: 'App',
  components: {
  'Footer': Footer,
  'navBar': NavBar
  },
}
</script>

<style src="./assets/css/tailwind.css">
</style>
