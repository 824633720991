import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

createApp(App)
	.use(router)
	.mount("#app");

const menuIcon = document.getElementById("menu-icon");
const menu = document.getElementById("menu");

menuIcon.addEventListener("click", () => {
	if (menu.classList.contains("hidden")) {
		menu.classList.remove("hidden");
	} else {
		menu.classList.add("hidden");
	}
});
